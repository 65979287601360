'use client';

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { AngledArrowRightIcon } from '../../ui/icons/icons';
import { RelLink } from '../../ui/rel-link';

type Props = {
  imagePath: string;
  name: string;
  shortDescription: string;
  btnText?: string;
  friendlyUrl?: string;
  campaignId?: number | string;
};

export const ListItem: React.FC<Props> = ({ imagePath, name, shortDescription, btnText, friendlyUrl, campaignId }) => {
  return (
    <li
      key={name}
      className={classNames(
        'flex max-w-[345px] flex-col justify-between gap-5 rounded-2xl p-4 shadow-campCardDetail md:p-5'
      )}
    >
      <div className="relative h-[160px] w-[268px] md:h-[190px] md:w-full">
        <Link href={`/kampanyalar/${friendlyUrl}`}>
          <Image
            className="rounded-xl"
            src={imagePath}
            alt={name + ' Kobi Kredisi'}
            decoding="async"
            quality={80}
            loading="lazy"
            fill
            sizes="(max-width: 1120px) 50vw, 33vw"
          />
        </Link>
      </div>
      <div>
        <h4 className="mb-4 text-base font-bold text-black800">{name}</h4>
        <p className="text-xs text-black700 md:text-sm">{shortDescription}</p>
      </div>
      <div className="flex flex-row items-center gap-2 text-sm font-semibold text-purple500">
        <RelLink href={`/kampanyalar/${friendlyUrl}`}>{btnText}</RelLink>
        <AngledArrowRightIcon className="text-xl text-purple500" style={{ color: 'red' }} />
      </div>
    </li>
  );
};
