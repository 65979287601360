export function isNullOrWhitespace(input: any): boolean {
  if (typeof input === 'undefined' || input == null) {
    return true;
  }

  return input.toString().replace(/\s/g, '').length < 1;
}

export const isEmailValid = (email: string) => {
  const regex = new RegExp(/^[\w-~+!#$=/`{}|^&'.]+@([\w-~+!#$=/`{}|^&']+\.)+[\w-~+!#$=/`{}|^&']{2,4}$/);
  return regex.exec(email);
};
